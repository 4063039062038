import React from "react"
import { useStaticQuery, graphql } from 'gatsby';
import { FaqDetail } from "../../../components/faq/detail"

const breadcrumbsData: { text: string; path: string }[] = [
  { text: `FAQ`, path: `question` },
  { text: `Web勤怠システム オツトメ！`, path: `question/otsutome` },
]

const faqNavItems: { text: string; path: string }[] = [
  { text: `Web勤怠システム オツトメ！`, path: `#faq_otsutome` },
]

const FaqTitle: string = "Web勤怠システム オツトメ！に関するよくある質問";

export default function FaqOtsutomePage() {
  const { site: { siteMetadata: { faqDetail } } } = useStaticQuery(graphql`
  query {
    site {
      siteMetadata {
        faqDetail {
          otsutome {
            details {
              answer
              question
            }
            path
            text
          }
        }
      }
    }
  }
`);

  const [ faqDetails ] = faqDetail;

  return (
    <FaqDetail
      breadcrumbsData={breadcrumbsData}
      faqNavItems={faqNavItems}
      faqDetails={faqDetails.otsutome}
      faqTitle={FaqTitle}
    />
  )
}